#site-header{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: $pc-head;
	padding: 0 40px;
	@include flex();
	align-items: center;
	z-index: 1000;
	@include transition(400ms);
	@media screen and (max-width: $tab){
		height: $tab-head;	
		padding: 0 30px;
	}
	@media screen and (max-width: $phone){
		height: $sp-head;
		padding: 0 15px;
	}
	&.active{
		background: #FFF;
		box-shadow: 0 0 16px rgba(#000,.08);
		&:after{
			opacity: 0;
		}
	}
	&:after{
		content: '';
		width: calc(100% - 80px);
		height: 1px;
		position: absolute;
		left: 40px;
		bottom: 0;
		background: #000;
		opacity: .06;
		@media screen and (max-width: $tab){
			width: calc(100% - 60px);
			left: 30px;
		}
		@media screen and (max-width: $phone){
			width: calc(100% - 30px);
			left: 15px;
		}
	}
	.logo{
		width: 200px;
		@media screen and (max-width: $tab){
			width: 160px;	
		}
		@media screen and (max-width: $phone){
			width: 150px;	
		}
		img{
			width: 100%;
		}
	}
	.gnavi{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		@media screen and (max-width: $phone){
			display: none;
		}
		ul{
			@include flex();
			align-items: center;
			padding: 0 0 0 2em;
			li{
				list-style: none;
				margin: 0 3.5vw 0 0;
				font-size: 14px;
				@media screen and (max-width: $tab){
					margin: 0 32px 0 0;
					&:first-child{
						display: none;
					}
				}
				&:last-child{
					margin: 0;
				}
				a{
					color: $normal;
					position: relative;
					&:hover{
						&:after{
							width: 48px;
						}
					}
					&:after{
						content: '';
						width: 0;
						height: 4px;
						background: $green;
						position: absolute;
						left: 50%;
						bottom: -30px;
						transform: translateX(-50%);
						@include transition(160ms);
						z-index: 2;
					}
				}
			}
		}
	}
	.meta{
		line-height: 1;
		text-align: right;
		dt{
			font-size: 11px;
			margin: 0 0 8px;
			@media screen and (max-width: $phone){
				font-size: 10px;	
			}
		}
		dd{
			@include osw();
			font-size: 12px;
			@media screen and (max-width: $phone){
				font-size: 11px;	
			}
			a{
				font-size: 22px;
				display: inline-block;
				vertical-align: bottom;
				margin: 0 0 0 4px;
				color: $normal;
				@media screen and (max-width: $tab){
					font-size: 18px;	
				}
				@media screen and (max-width: $phone){
					font-size: 16px;	
				}
			}
		}
	}
}