.site-index{
	.first{
		position: relative;
		background: $glay;
		&.wrap{
			padding-top: 64px;
			@media screen and (max-width: $phone){
				padding-top: 32px;	
			}
		}
		&__box{
			@include flex();
			align-items: flex-start;
			position: relative;
			@media screen and (max-width: $phone){
				display: block;
			}
			&__bg{
				width: 56%;
				height: 400px;
				background: url("../../../images/index/photo_bg_01.jpg") no-repeat center;
				background-size: cover;
				@media screen and (max-width: $tab){
					width: 50%;
					height: 280px;
				}
				@media screen and (max-width: $phone){
					width: auto;
					height: 200px;
					margin: 0 15px;
				}
			}
			&__txt{
				width: 44%;
				padding: 120px 60px;
				@media screen and (max-width: $tab){
					width: 50%;
					padding: 64px 40px;
				}
				@media screen and (max-width: $phone){
					width: auto;
					padding: 24px 15px 0;
				}
				p{
					max-width: 640px;
					min-width: 400px;
					margin: 0 auto;
                    @include fontsize(24px,6);
                    font-weight: 700;
                    line-height: 1.6;
					@media screen and (max-width: $tab){
						min-width: 1px;
						max-width: 100%;
						br{
							display: none;
						}
					}
					@media screen and (max-width: $phone){
						font-size: 18px;
						text-align: center;
						br{
							display: inline;
						}
					}
                    &:before{
                        content: attr(data-eng);
                        color: $green;
                        @include fontsize(10px,2);
                        line-height: 1;
                        letter-spacing: .12em;
                        display: block;
                        margin: 0 0 16px;
                        text-indent: .3em;
						@media screen and (max-width: $phone){
							margin: 0 0 8px;	
						}
                    }
				}
			}
		}
        &__tt{
            position: absolute;
            right: 3.3vw;
            bottom: 16px;
			line-height: 1;
			color: darken($glay, 8%);
			@include fontsize(144px,32);
			font-weight: 700;
			white-space: nowrap;
			@media screen and (max-width: $tab){
				@include fontsize(124px,32);
				bottom: 0;
			}
			@media screen and (max-width: $phone){
				display: none;
			}
        }
	}
	
	.second{
        background: $glay;
		@media screen and (max-width: $phone){
			border-top: rgba(#000,.06) 1px solid;
		}
		&__list{
			margin: 0 0 0 -40px;
			@include flex();
			@media screen and (max-width: $phone){
				margin: 0;
				display: block;
			}
			li{
				width: 50%;
				margin: 40px 0 0;
				padding: 0 0 0 40px;
				position: relative;
				@media screen and (max-width: $phone){
					width: auto;
					margin: 0 0 24px;
					padding: 0;
					&:last-child{
						margin: 0;
					}
				}
				&:nth-child(-n+2){
					margin-top: 0;
				}
				&:before{
					@include osw();
					content: '01';
					font-size: 20px;
					position: absolute;
					top: 0;
					right: 0;
					z-index: 2;
					background: $brown;
					color: #FFF;
					width: 56px;
					height: 56px;
					@include flex();
					align-items: center;
					justify-content: center;
				}
				@for $i from 1 through 4{
					&:nth-child(#{$i}){
						&:before{
							content: '0#{$i}';
						}
					}
				}
				figure{
					img{
						width: 100%;
					}
				}
				dl{
					padding: 15px 0 0;
					dt{
						font-size: 17px;
						font-weight: 700;
						&:after{
							content: attr(data-eng);
							font-size: 10px;
							display: inline-block;
							margin: 0 0 0 16px;
							color: $normal;
							text-transform: uppercase;
							letter-spacing: .12em;
							opacity: .28;
						}
					}
					dd{
						font-size: 13.5px;
						text-align: justify;
						margin: 14px 0 0;
						padding: 16px 0 0;
						border-top: $border 1px solid;
						position: relative;
						line-height: 1.66;
						@media screen and (max-width: $phone){
							font-size: 13px;	
						}
						&:before{
							content: '';
							width: 20%;
							height: 1px;
							background: $brown;
							position: absolute;
							top: -1px;
							left: 0;
						}
					}
				}
			}
		}
	}
	
	.third{
		position: relative;
		background: $glay;
		@media screen and (max-width: $phone){
			padding-top: 32px !important;
			border-top: rgba(#000,.06) 1px solid;
		}
		$size: 96px;
		&__list{
			margin: 0 0 0 -40px;
			@include flex();
			justify-content: flex-start;
			@media screen and (max-width: $phone){
				margin: 0;	
			}
			li{
				width: 33.3%;
				padding: 0 0 0 40px;
				margin: 40px 0 0;
				position: relative;
				&:nth-child(-n+3){
					margin-top: 0;
				}
				@media screen and (max-width: $phone){
					width: 48%;
					padding: 0;
					margin: 0 4% 4% 0;
					&:nth-child(2n+2){
						margin-right: 0;
					}
					&:nth-last-child(-n+2){
						margin-bottom: 0;
					}
				}
				figure{
					img{
						width: 100%;
					}
				}
				figcaption{
					margin: 12px 0 0;
					font-size: 14px;
					font-weight: 700;
					@media screen and (max-width: $phone){
						margin: 8px 0 0;
						font-size: 13.5px;
					}
				}
			}
		}
	}
	
	.four{
		background: $glay;
		.map{
			margin: 48px 0 0;
			@media screen and (max-width: $phone){
				margin: 24px 0 0;	
			}
			iframe{
				width: 100%;
				height: 400px;
				vertical-align: bottom;
				filter: grayscale(1);
				@media screen and (max-width: $phone){
					height: 240px;	
				}
			}
		}
	}
	
	.fifth{
		background: $glay;
		.formalert{
			font-size: 18px;
			margin: 0 0 20px;
			font-weight: 700;
			color: $normal;
			@media screen and (max-width: $phone){
				font-size: 14px;	
			}
		}
		.send{
			margin: 32px 0 0;
			text-align: center;
			@media screen and (max-width: $phone){
				margin: 24px 0 0;	
			}
			input{
				background: $yellow;
				border: $yellow 2px solid;
				color: #FFF;
				display: inline-block;
				padding: 16px 0;
				width: 320px;
				font-size: 17px;
				font-weight: 700;
				cursor: pointer;
				@include transition(200ms);
				@media screen and (max-width: $phone){
					width: 100%;
					display: block;
					-webkit-appearance: none;
				}
				&:hover{
					background: #FFF;
					color: $yellow;
				}
			}
		}
	}
}