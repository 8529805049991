.site-index{
	.firstview{
		width: 100%;
		height: 100vh;
		min-height: 640px;
		position: relative;
		@include flex();
		align-items: center;
		justify-content: flex-start;
		overflow: hidden;
		@media screen and (max-width: $phone){
			min-height: 1px;
			height: 400px;
		}
		#indexswipe{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			.swiper-slide{
				width: 100%;
				height: 100vh;
				position: relative;
				@media screen and (max-width: $phone){
					height: 400px;	
				}
				&__bg{
					background-position: center;
					background-size: cover;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					img{
						display: none;
					}
				}
			}
            .swiper-slide-active .swiper-slide__bg,
            .swiper-slide-duplicate-active .swiper-slide__bg,
            .swiper-slide-prev .swiper-slide__bg{
                animation: zoomUp 8s linear 0s 1 normal both;
            }
            @keyframes zoomUp {
                0% {
                    transform: scale(1.08);
                }
                100% {
                    transform: scale(1);
                }
            }
		}
		&__content{
			position: relative;
			margin: 0 0 0 8vw;
			z-index: 2;
			&__eng{
				@include osw();
				font-size: 96px;
				text-transform: uppercase;
				line-height: 1.2;
				letter-spacing: .048em;
				@media screen and (max-width: $tab){
					font-size: 64px;	
				}
				@media screen and (max-width: $phone){
					font-size: 38px;	
				}
			}
			h1{
				font-size: 26px;
				font-weight: 700;
				margin: 8px 0 0;
				letter-spacing: .08em;
				@media screen and (max-width: $tab){
					font-size: 20px;	
				}
				@media screen and (max-width: $phone){
					font-size: 13px;	
				}
			}
		}
		&__copy{
			position: absolute;
			left: 40px;
			bottom: 32px;
			z-index: 2;
			font-size: 10px;
			@media screen and (max-width: $phone){
				left: 15px;
				bottom: 15px;
			}
		}
		&__pager{
			
		}
        &__scroll{
            -moz-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            white-space: nowrap;
            font-size: 10px;
            letter-spacing: .24em;
            position: absolute;
            bottom: 0;
            right: 40px;
            line-height: 1;
            padding: 0 0 96px;
			z-index: 2;
			@media screen and (max-width: $phone){
				display: none;
			}
            &:before,
            &:after{
                content: '';
                width: 1px;
                height: 72px;
                background: #FFF;
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
            }
            &:before{
                background: $normal;
            }
            &:after{
                animation: sc 1600ms infinite;
                @keyframes sc{
                    0%{
                        height: 72px;
                        bottom: 0;
                    }
                    33%{
                        height: 0;
                        bottom: 0;
                    }
                    65%{
                        height: 0;
                        bottom: 72px;
                    }
                    66%{
                        height: 0;
                        bottom: 72px;
                    }
                    100%{
                        height: 72px;
                        bottom: 0;
                    }
                }
            }
        }
		&__bg{
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 100%;
			background: $glay;
			z-index: 500;
		}
	}
}