@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap');

@import "module/reset";
@import "module/module";

//USE ASSETS
@import "plugin/swiper";

/* BASE COLOR
------------------------------------------------------------*/
$normal:#404040;
$link:#D02;
$border:rgba($normal,.16);
$glay: #e4e4db;
$green: #648371;
$brown: #73645d;
$yellow: #c9b38d;


/* SITE SETTING
------------------------------------------------------------*/
$width: 1120px;
$tab: $width - 1px;
$phone: 640px;

$pc-head: 80px;
$tab-head: 70px;
$sp-head: 60px;


@media screen and (min-width: $width){
	@include default(
		$width, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		40px, //パディング
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $tab){
	@include default(
		auto, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
        30px, //パディング
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $phone){
	@include default(
		auto, //サイト幅
		12px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
        15px, //パディング
		#FFF //ボディ背景
	);
}

@mixin fontsize($w: 32px, $m: 22){
    font-size: calc(#{$w} + #{$m} * (100vw - 450px)/ 900);
}

@import "common/header";
@import "common/footer";
@import "page/firstview";
@import "page/index";

#site-wrapper,#site-footer{
    position: relative;
}

.site-module{
	&.wrap{
		padding: 112px 0;
		@media screen and (max-width: $tab){
			padding: 40px 0;
		}
		@media screen and (max-width: $phone){
			padding: 32px 0;	
		}
		&.x{
			padding-top: 160px;
			@media screen and (max-width: $tab){
				padding-top: 112px;	
			}
			@media screen and (max-width: $phone){
				padding-top: 88px;
			}
		}
		&.mid{
			padding: 80px 0;
			@media screen and (max-width: $phone){
				padding: 32px 0;	
			}
		}
		&.mid-bottom{
			padding-bottom: 80px;
			@media screen and (max-width: $phone){
				padding-bottom: 32px;	
			}
		}
		&.top-half{
			padding-top: calc(112px / 1.4);
			@media screen and (max-width: $phone){
				padding-top: 32px;	
			}
		}
		&.no-top{
			padding-top: 0;
		}
		&.no-bottom{
			padding-bottom: 0;
		}
	}
	.col-green{
		color: $green;
	}
	h2.large{
		text-align: left;
		font-size: 18px;
		font-weight: 700;
		line-height: 1;
		margin: 0 0 32px;
		&:before{
			@include osw();
			content: attr(data-eng);
			display: inline-block;
			font-size: 48px;
			margin: 0 20px 0 0;
		}
		@media screen and (max-width: $phone){
			
		}
	}
	h2.center{
		text-align: center;
		font-size: 40px;
		margin: 0 0 40px;
		line-height: 1;
		letter-spacing: .06em;
		font-weight: 700;
		&:before{
			content: attr(data-eng);
			font-size: 12px;
			display: block;
			margin: 0 0 10px;
			letter-spacing: .2em;
		}
		@media screen and (max-width: $phone){
			font-size: 24px;
			margin: 0 0 24px;
			&:before{
				font-size: 9px;
			}
		}
	}
	.display-wide{
		padding: 0 calc(4vw + 40px);
		@media screen and (max-width: $phone){
			padding: 0 15px;	
		}
	}
	.tablestyle{
		border: $border 1px solid;
		border-collapse: collapse;
		width: 100%;
		background: #FFF;
		@media screen and (max-width: $phone){
			display: block;	
		}
		&.formstyle{
			border: none;
			tbody{
				tr{
					th,td{
						padding: 32px 24px;
						@media screen and (max-width: $phone){
							padding: 0;	
						}
					}
					th{
						width: 240px;
						@media screen and (max-width: $phone){
							width: auto;
							font-size: 15px;
						}
						&.hiss{
							&:after{
								content: '必須';
								background: $yellow;
								color: #FFF;
								border-radius: 3px;
								float: right;
								padding: 3px 12px;
								font-size: 13px;
								@media screen and (max-width: $phone){
									font-size: 11px;
									padding: 2px 15px;
									transform: translateY(-2px);
								}
							}
						}
					}
					td{
						padding-left: 40px;
						@media screen and (max-width: $phone){
							padding: 6px 0 0;	
						}
						input[type=text],
						input[type=email],
						input[type=tel],
						input[type=password],
						textarea{
							border: none;
							background: lighten($glay ,2%);
							padding: 12px;
							@include font();
							font-size: 15px;
							width: 100%;
							outline: none;
							@include transition(200ms);
							border-radius: 3px;
							&:focus{
								background: #FFF;
								box-shadow: 0 0 32px rgba(#000,.12);
							}
						}
						textarea{
							height: 240px;
						}
					}
				}
			}
		}
		tbody{
			@media screen and (max-width: $phone){
				display: block;
			}
			tr{
				border-bottom: $border 1px solid;
				@media screen and (max-width: $phone){
					display: block;
					padding: 15px;
				}
				&:last-child{
					border: none;
				}
				th,td{
					padding: 24px;
					vertical-align: middle;
					@media screen and (max-width: $phone){
						display: block;
						padding: 0;
					}
				}
				th{
					text-align: left;
					width: 200px;
					@media screen and (max-width: $phone){
						width: auto;
						font-size: 16px;
					}
				}
				td{
					
				}
			}
		}
	}
	.btnarea{
		text-align: center;
		a{
			background: $yellow;
			border: $yellow 2px solid;
			color: #FFF;
			padding: 10px 56px;
			font-size: 17px;
			display: inline-block;
			@include transition(200ms);
			&:hover{
				background: #FFF;
				color: $yellow;
			}
		}
	}
}

.site-common{
	.context{
		text-align: center;
		margin: 0 0 24px;
		font-size: 16px;
	}
}

#side{
	position: fixed;
	top: 50%;
	right: 0;
	z-index: 999;
	transform: translateX(101%) translateY(-50%);
	background: $green;
	color: #FFF;
	padding: 32px 11px;
	-moz-writing-mode: vertical-rl;
	-ms-writing-mode: tb-rl;
	-webkit-writing-mode: vertical-rl;
	writing-mode: vertical-rl;
	white-space: nowrap;
	font-size: 14.5px;
	letter-spacing: .24em;
	transition: transform 400ms;
	@media screen and (max-width: $tab){
		font-size: 13px;
		padding: 24px 10px;
	}
	@media screen and (max-width: $phone){
		display: none;
	}
	&.active{
		transform: translateX(0) translateY(-50%);
	}
	> i{
		display: inline-block;
		margin: 0 0 8px;
	}
}